#show-section {
    display: flex;
    flex-direction: column;
    padding-top: 45px;
}
.show-section-wrapper {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2b2b2be0; 
    border-radius: 25px;
    width: 80%;
    gap: 30px;
    align-self: center;
}
.show-title {
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: "Francois One", sans-serif;
    font-size: 55px;
    color: #fff;
    text-shadow: 4px 4px 4px black;
}
@media only screen and (max-width: 768px) {
    .show-title{
        font-size: 36px;
    }
    #show-section{
        padding-top: 21px;
    }
}
