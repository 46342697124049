#stickyHeader{
    position: fixed;
    top: 0px;
}
.nav-logo, .menu-button, .nav-media-item  {
    width: 4.2rem;
    cursor: pointer;
    border-radius: 10px;
}
.nav-media-item:hover{
    width: 4.5rem;
}
.nav-media-links{
    display: flex;
    gap: 40px
}
.nav-logo-cont{
    margin-left: 2%;
}
.menu-button-cont{
    margin-right: 2%;
}
.header-links-wrapper{
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    height: 115px;
    background: #2b2b2be0;
    align-items:center;
    letter-spacing: 2px;
    z-index:1;
}
/* MID_SIZE MOBILE STYLING */
@media only screen and  (max-width: 768px) {
    .header-links-wrapper{
        height: 90px;
    }
    .nav-logo{
        width: 5rem;
    }
    .nav-media-item{
        width: 3.2rem;
    }
    .nav-media-links{
        gap: 20px;
    }
}
/* SMALL MOBILE STYLING */
@media only screen and  (max-width: 476px) {
    .nav-logo, .menu-button, .nav-media-item {
        width: 3.5rem;
    }
    .menu-button{
        margin-right: 5px;
    }
}