.email-foot{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}
.want-email{
    font-size: 35px;
    margin-bottom: 50px;
    font-family: "Francois One", sans-serif;
    color: #fff;
    text-shadow: 4px 4px 4px black;
}
.email-button {
    display: inline-block;
    padding: 15px 30px; 
    background-color: #fff;
    color: white;
    text-decoration: none;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}
.email-button:hover {
    background-color: #4d4d4df5;
}
.kokachin {
    width: 6rem;
}
@media only screen and (max-width: 768px) {
    .want-email {
        text-align: center;
        margin-bottom: 20px;
        font-size: 36px;
    }
    .contact-foot{
        margin-top: 160px;
    }
    .kokachin{
        width: 2rem;
    }
}
