.about-index-item-wrapper {
    font-size: 40px;
    color: white;
    gap: 40px;
    width: 90%;
    background: #000b6496;
    padding: 5%;
    border-radius: 20px;
    margin-bottom: 50px;
}
.rocker-tag{
    float: left;
    padding-right: 30px;
}
.rocker-image {
    width: 100%;
    height: 17rem;
    border-radius: 45px;
}
.rocker-name {
    font-family: "Francois One", sans-serif;
    font-size: 58px;
    color: #fff;
    text-shadow: 4px 4px 4px black;
    margin-bottom: 20px;
}
.instrument, .rocker-desc {
    font-family: 'Dosis', sans-serif;
    font-size: 36px;
    color: #fff;
    text-shadow: 2px 2px 2px black;
    line-height: 55px;
    margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
    .about-index-item-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .rocker-desc-cont{
        font-size: 26px;
        line-height: 45px;
    }
    .rocker-name{
        gap: 15px;
        font-size: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .instrument{
        padding: 0;
        text-align: center;
    }
    .rocker-tag{
        padding: 0;
    }
    /* .hide-mobile{
        display: none;
    } */
}